<template>
<div class="page-consulta-ventas_enlinea">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Consultar Pedidos en Línea" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
              <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-autocomplete v-model="model.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true" :loading="loading_sucursal"
                    item-text="nombre" item-value="_id" label="Sucursal"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="6" lg="6">
                  <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha De" color="secondary" prepend-icon="event"
                        @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu1 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                  <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha A" color="secondary" prepend-icon="event"
                        @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu2 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.native="consultar_ventas()">
          <v-icon>search</v-icon> Consultar
        </v-btn>
      </v-card-actions>

      <v-card-text>
            <div id="datagrid">

                <v-client-table ref="vuetable" :data="registros" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>

                    <template slot="actions" slot-scope="props" >
                        <v-speed-dial direction="left" open-on-hover >
                            <template v-slot:activator>
                                <v-btn x-small fab dark color="primary">
                                    <v-icon>reorder</v-icon>
                                </v-btn>
                            </template>
                            <v-btn x-small fab dark color="red"
                                v-if="(['En Proceso'].includes(props.row.estatus) && ['Enviado','En Proceso','Listo Para Recoger'].includes(props.row.estatus_pedido) && props.row.tipo_venta=='Mayoreo')"
                                @click.native="cancelar_venta(props.row._id)" title="Cancelar Pedido" v-tippy>
                                <v-icon>cancel</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="blue" @click.native="show_detalle(props.row._id)" title="Ver detalle" v-tippy>
                                <v-icon>list</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="primary"
                                v-if="(props.row.articulos_devolver.length>0 && props.row.tipo_venta=='Mayoreo')"
                                @click.native="imprimirOrdenEntrada(props.row._id)" title="Orden Devolución" v-tippy>
                                <v-icon>mdi-package-down</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="success"
                                v-if="(props.row.tipo_venta=='Mayoreo')"
                                @click.native="imprimirOrdenSalida(props.row._id)" title="Orden Salida" v-tippy>
                                <v-icon>mdi-package-up</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="blue darken-2"
                                v-if="(['Enviado','En Proceso','Listo Para Recoger'].includes(props.row.estatus_pedido))"
                                @click.native="cambiarEstatus(props.row)" title="Editar Estatus" v-tippy>
                                <v-icon>mdi-more</v-icon>
                            </v-btn>

                        </v-speed-dial>
                    </template>
                </v-client-table>
            </div>
        </v-card-text>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal_detalle" max-width="70%" v-if="(model_detalle!='' && model_detalle!=undefined)">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">list</v-icon>&nbsp;
          <span class="subheading">
            <strong>Detalle de Venta</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_detalle = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs grow style="margin-bottom: 30px;" v-model="active_tab">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab key="datos">
            <v-card-title><b>Datos Generales</b></v-card-title>
          </v-tab>
          <v-tab key="articulos" v-show="(model_detalle.articulos!=undefined && model_detalle.articulos.length>0)">
            <v-card-title><b>Artículos</b></v-card-title>
          </v-tab>
          <v-tab key="devolucion" v-show="(model_detalle.articulos_devolver!=undefined && model_detalle.articulos_devolver.length>0)">
            <v-card-title><b>Artículos Devolución</b></v-card-title>
          </v-tab>

          <v-tab-item key="datos" style="margin: 20px;">
            <fieldset>
              <legend><strong>Información General</strong></legend>
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col>
                      <strong>Tipo Venta:</strong><br>
                      {{model_detalle.tipo_venta=='super'?'Menudeo':"Mayoreo"}}
                    </v-col>

                    <v-col>
                        <strong>#Ticket:</strong><br>
                      {{model_detalle.ticket}}
                    </v-col>

                    <v-col>
                        <strong>Sucursal:</strong><br>
                      {{model_detalle.sucursal.nombre}}
                    </v-col>

                    <v-col>
                        <strong>Fecha:</strong><br>
                      {{model_detalle.fecha.substring(0,16).replace("T"," ") }}
                    </v-col>
                  </v-row><br>

                  <v-row>
                      <v-col>
                        <strong>Caja:</strong><br>
                      {{model_detalle.caja_venta }}
                    </v-col>

                    <v-col>
                      <strong>Empleado:</strong><br>
                      {{model_detalle.usuario_venta}}
                    </v-col>

                    <v-col>
                        <strong>Cliente:</strong><br>
                      {{model_detalle.cliente.nombre}}
                    </v-col>

                    <v-col>
                        <strong>Observaciones:</strong><br>
                      {{model_detalle.observaciones}}
                    </v-col>
                  </v-row><br>

                   <v-row>
                    <v-col>
                        <strong>Estatus:</strong><br>
                      {{model_detalle.estatus}}
                    </v-col>
                    <v-col>
                      <strong>Subtotal:</strong><br>
                      {{model_detalle.subtotal.toFixed(2)}}
                    </v-col>

                    <v-col>
                        <strong>Impuestos:</strong><br>
                      {{model_detalle.impuestos.toFixed(2)}}
                    </v-col>

                    <v-col>
                        <strong>Total:</strong><br>
                        {{model_detalle.total.toFixed(2)}}
                    </v-col>
                  </v-row><br>

                  <v-row>
                    <v-col v-if="model_detalle.cancelado_por!=''">
                        <strong>Cancelado Por:</strong><br>
                        {{model_detalle.cancelado_por.nombre}}
                    </v-col>
                    <v-col v-else>
                        <strong></strong><br>
                    </v-col>
                    <v-col v-if="model_detalle.fecha_salida!=''">
                      <strong>Fecha Salida:</strong><br>
                    {{model_detalle.fecha_salida.substring(0,16).replace("T"," ") }}
                    </v-col>
                    <v-col v-if="model_detalle.fecha_salida!=''">
                        <strong>Observaciones Salida:</strong><br>
                      {{model_detalle.observacion_salida}}
                    </v-col>
                    <v-col v-if="model_detalle.fecha_salida!=''">
                        <strong>Usuario Salida:</strong><br>
                        {{model_detalle.usuario_salida}}
                    </v-col>
                  </v-row><br>

                  <v-row v-if="model_detalle.articulos_devolver.length>0">
                    <v-col>
                        <strong>Estatus Devolución:</strong><br>
                        {{model_detalle.estatus_entrada}}
                    </v-col>
                    <v-col>
                      <strong>Fecha Entrada:</strong><br>
                    {{model_detalle.fecha_entrada.substring(0,16).replace("T"," ") }}
                    </v-col>

                    <v-col>
                        <strong>Observaciones Entrada:</strong><br>
                      {{model_detalle.observaciones_entrada}}
                    </v-col>

                    <v-col>
                        <strong>Usuario Entrada:</strong><br>
                        {{model_detalle.usuario_entrada}}
                    </v-col>
                  </v-row>


                </v-container>
              </v-form>
            </fieldset>
          </v-tab-item>

          <v-tab-item key="articulos" style="margin: 20px;">
            <fieldset>
              <legend><strong>Artículos:</strong></legend>
                <v-container grid-list-md>

                  <v-client-table :data="model_detalle.articulos" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                    <template slot="codigo_barras" slot-scope="props">
                        {{props.row.codigo_barras.join(", ")}}
                    </template>
                    <template slot="precio" slot-scope="props">
                        {{ formatNumber(parseFloat(props.row.precio).toFixed(2)) }}
                    </template>
                    <template slot="total" slot-scope="props">
                        {{ formatNumber(parseFloat(props.row.total).toFixed(2)) }}
                    </template>
                </v-client-table>

                </v-container>

            </fieldset>
          </v-tab-item>

          <v-tab-item key="devolucion" style="margin: 20px;">
            <fieldset>
              <legend><strong>Artículos en Devolución:</strong></legend>
              <v-container grid-list-md>

                <v-client-table :data="model_detalle.articulos_devolver" :columns="columnsDevolucion" :options="optionsDevolucion" class="elevation-2 pa-2">
                    <template slot="codigo_barras" slot-scope="props">
                        {{props.row.codigo_barras.join(", ")}}
                    </template>
                </v-client-table>

              </v-container>
            </fieldset>
          </v-tab-item>

        </v-tabs>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_detalle = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

   <v-dialog v-model="modalEstatus" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Editar Estatus</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalEstatus = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="10" md="10" lg="10" style="text-align:center !important;">
                                <v-autocomplete v-model="estatus_editable" :items="select_estatus_pedido" :hide-no-data="true" item-text="id" item-value="id" label="Seleccione Estatus" :rules="[rules.required]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalEstatus = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="changeEstatus()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalCorreo" max-width="50%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">email</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Enviar por Correo</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCorreo = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcorreo" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field label="Enviar a" v-model="correo.correos" persistent-hint hint="Correos separados por coma" :rules="[rules.required, validaCorreo]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Asunto" v-model="correo.asunto" :rules="[rules.required]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Texto del Correo" v-model="correo.mensaje" :rules="[rules.required]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Responder a" v-model="correo.responder_a" persistent-hint hint="Correos separados por coma" :rules="[rules.required, validaCorreo]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalCorreo = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="enviarCorreo()">
                                <v-icon>done</v-icon> Enviar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import Vue from "vue";
import "jsbarcode";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },

  },
  mounted: function() {
    /*if (!this.verificaPermiso('catalogos.proveedores')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }*/
  },
  created: function() {
    this.model = this.clean_model();
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha_de = today;
    this.model.fecha_a = today;
    this.get_sucursales();
  },
  data() {
    return {
        correo: {
            correos: "",
            responder_a: "",
            asunto: "",
            mensaje: ""
        },
        modalCorreo: false,
        select_estatus_pedido:["En Proceso","Listo Para Recoger"],
        estatus_editable:"",
        model_vista:"",
        modalEstatus:false,
        active_tab:"datos",
        modal_detalle:false,
        //model_detalle:{"articulos":[],"articulos_devolver":[]},
        model_detalle:"",
        registros:[],
        columns: [
            "tipo_venta",
            "ticket",
            "sucursal.nombre",
            "fecha","caja_venta", "cliente.nombre",
            "total","estatus","estatus_pedido","devolucion",
            "actions"
        ],
        options: {
            filterable: ["tipo_venta","ticket","sucursal.nombre",
            "fecha","caja_venta", "cliente.nombre",
            "total","estatus","devolucion"],
            sortable: ["tipo_venta","ticket","sucursal.nombre",
            "fecha","caja_venta", "cliente.nombre",
            "total","estatus","devolucion"],
            headings: {
                "tipo_venta":"Tipo",
                "ticket":"#Ticket",
                "sucursal.nombre":"Sucursal",
                "caja_venta":"Caja Venta",
                "cliente.nombre":"Cliente",
                "total":"Importe Total",
                "devolucion":"Devolución?",
                estatus: "Estatus Venta",
                estatus_pedido:"Estatus Pedido",
                actions: "Operaciones"
            },
        },
        columnsArticulos:["codigo_barras","nombre","descripcion","cantidad","precio","total"],
        optionsArticulos:{
            filterable: false,
            headings: {
            }
        },
        columnsDevolucion:["codigo_barras","nombre","descripcion","cantidad_devolver","estatus_entrada","cantidad_recibida"],
        optionsDevolucion:{
            filterable: false,
            headings: {
            }
        },
        loading_sucursal:false,
        sucursales:[],
        menu1:false,
        menu2:false,
        menu3:false,
        menu4:false,
      search_empleado:'',
      search_marca: null,
      tipos_precios: [],
      categorias:[],
      marcas:[],
      clientes:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_cliente: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Ventas",
          disabled: true,
          href: ""
        },
        {
          text: "Pedidos en Línea",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
      show_detalle:function(id_venta){
        window.dialogLoader.show('Espere un momento por favor..');

        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_venta)
        .then(response => {
            this.model_detalle = response.data;
            if(!this.model_detalle.cancelado_por.hasOwnProperty('nombre') )
                this.model_detalle.cancelado_por = "";

            if(this.model_detalle.articulos_devolver == undefined){
                this.model_detalle["articulos_devolver"] = [];
            }
            this.active_tab = "datos";
            this.modal_detalle = true;
        }) .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar la venta. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });
      },
    get_sucursales: function () {
        this.loading_sucursal = true;
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": {
                    "$eq": "Activo"
                }
            },
            "fields": [
                "nombre","clave", "_id"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];
                if (response.data.docs.length > 0)
                    this.sucursales = response.data.docs;
                    let todas = {
                        _id: '0',
                        nombre: 'TODAS'
                    };
                    this.sucursales.unshift(todas);
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            }).then(()=>{
                this.loading_sucursal = false;
            });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },

    clean_model: function() {
      return {
        id_sucursal:"0",
        fecha_de: '',
        fecha_a: '',
      };
    },
    consultar_ventas:function(){
        window.dialogLoader.show('Espere un momento por favor..');
        let data = {
            "selector": {
                "type":"ventas",
                "caja_venta":"WEB", //Las ventas en linea tendrán por default esta caja
                "fecha": {
                    "$gte": this.model.fecha_de,
                    "$lte": this.model.fecha_a+ "T23:59:59.999"
                },          
            },
            "sort": [{"fecha":"desc"}],
            "fields": ["_id", "tipo_venta",
                "ticket", "sucursal", "fecha","caja_venta","usuario_venta", "cliente",
                "total","estatus","estatus_pedido","articulos_devolver"
            ]
        };

        if(this.model.id_sucursal != undefined && this.model.id_sucursal != null && this.model.id_sucursal != "0"){
            data["selector"]["sucursal._id"] = this.model.id_sucursal;
        }

        window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
            if (response.data.docs.length > 0){
                this.registros = response.data.docs;
                //TODO: Preguntar si se deben mostrar las ventas 'En Proceso',
                //ya que pudiera ser que la editen cuando todavía esta el cajero atendiendo esa venta
                for(var key in this.registros){
                    this.registros[key]["tipo_venta"] = this.registros[key]["tipo_venta"]=="super" ? "Menudeo" : "Mayoreo";
                    this.registros[key]["fecha"] = this.registros[key]["fecha"].substring(0,16).replace("T"," ");
                    this.registros[key]["total"] = this.formatNumber(parseFloat(this.registros[key]["total"]).toFixed(2));
                    this.registros[key]["devolucion"] = "No";
                    if(this.registros[key]["articulos_devolver"] == undefined){
                        this.registros[key]["articulos_devolver"] = [];
                    } else if(this.registros[key]["articulos_devolver"].length>0){
                        this.registros[key]["devolucion"] = "Si";
                    }
                }
            }else
                this.registros = [];
        })

        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar las ventas. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });

    },
    generar_reporte: function(){
        if(this.$refs.form.validate()){
        try {
                window.dialogLoader.show('Espere un momento por favor..');

                window.axios
                    .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_ventas_empleados/", this.model, {
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                    })
                    .then(response => {
                        var bytes = response.data;
                        let blob = new Blob([bytes], {
                                type: response.headers["content-type"]
                            }),
                            url = window.URL.createObjectURL(blob);

                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = "Ventas Empleados.xlsx";
                        a.click();
                        a.parentNode.removeChild(a);
                    })
                    .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                        footer: ""
                    });
                    }).then(()=>{
                    window.dialogLoader.hide();
                    });
            } catch (error) {
            console.log("ERRORS: ",error);
            }
        }
    },

    imprimirOrdenEntrada: function(id_venta){
         window.dialogLoader.show('Espere un momento por favor..');
        var self = this;

        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_venta)
        .then(response => {
            this.model_detalle = response.data;

            var img = document.createElement("img");
            JsBarcode(img, this.model_detalle["ticket"], {format: "CODE128"});

            var columns = [
                {title: "Cantidad", dataKey: "cantidad"},
                {title: "Artículo", dataKey: "nombre_articulo"},
                {title: "Código", dataKey: "codigo_articulo"},
                {title: "Descripción", dataKey: "descripcion"},
            ];

            //setTimeout(function(){
                var doc = new jsPDF('p', 'mm', 'letter');

                doc.setFontSize(14);
                doc.setFont('Helvetica','bold');
                doc.text('LA BODEGUITA DE GUADALUPE', 120, 23, {align:'center'});
                doc.setFontSize(9);
                doc.text('RFC: BGU960207S5A', 120, 30, {align:'center'});
                doc.setFontSize(12);
                doc.text('Orden de Entrada', 15, 40);
                doc.setFontSize(8);
                doc.setFont('Helvetica','normal');
                doc.text('Devolución/Cancelación de venta', 15, 45);
                doc.text('Cliente: '+this.model_detalle["cliente"]["nombre"], 15, 50);
                doc.text('Sucursal: '+this.model_detalle["sucursal"]["nombre"], 15, 55);
                doc.text('Fecha Elaboración: '+ this.parse_date_time_mx(this.model_detalle["fecha"]), 15, 60);
                doc.text('Fecha Impresión: '+ this.parse_date_time_mx(window.moment()), 15, 65);
                doc.text('Le Atendió: '+this.model_detalle["usuario_venta"], 15, 70);
                doc.addImage(img.src, 'PNG', 145, 40, 50, 17);

                doc.setFontSize(8);
                doc.autoTable(columns, this.model_detalle["articulos_devolver"], {
                    startY: 75,
                    headStyles:{fontSize:8},
                    bodyStyles:{fontSize:8},
                    columnStyles: { codigo_articulo:{fontSize:6}, cantidad: { halign: 'right' } },

                });

                doc.save(this.model_detalle["ticket"]+"_orden_devolucion.pdf");
                window.dialogLoader.hide();
            //},500);

        }) .catch(error => {
            console.log(error);
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar la venta. Intente nuevamente.",
                footer: ""
            });
            window.dialogLoader.hide();
        });
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },

    cambiarEstatus(row) {
        window.dialogLoader.show('Espere un momento por favor..');

          window.axios
          .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
          .then(response => {
              this.model_vista = response.data;
              this.estatus_editable = row.estatus;
              this.modalEstatus = true;

          }) .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al consultar la venta. Intente nuevamente.",
                  footer: ""
              });
          }).then(()=>{
              window.dialogLoader.hide();
          });
    },
    changeEstatus() {
        try {
            window.dialogLoader.show('Espere un momento por favor..');

            this.model_vista.estatus_pedido = this.estatus_editable;

            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                .then(response => {
                    this.model_vista._rev = response.data.rev;
                    if (this.model_vista.estatus_pedido == "Listo Para Recoger"){
                        //Enviar correo
                        this.showModalCorreo(this.model_vista);
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al editar el estatus. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    this.modalEstatus = false;
                    window.dialogLoader.hide();
                    this.consultar_ventas();
                });

        } catch (error) {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                footer: ""
            });
            console.log(error);
            window.dialogLoader.hide();
        }

    },

    validaCorreo: function (val) {
        if (val != null && val != "") {
            var correos = val.split(",");
            for (var k in correos) {
                var correo = correos[k].toString().trim();
                if (!window.funciones.validaCorreo(correo)) {
                    return "Hay un correo no valido. Favor de verificar.";
                    break;
                }
            }
        }
        return true;
    },

    showModalCorreo: function(model){

        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;

        window.axios.get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + model.cliente._id)
        .then(response => {
             var cliente = response.data;
             this.correo= {
                    correos: cliente.email,
                    responder_a: "baroma_2@yahoo.com",
                    asunto: "BODEGUITA: Pedido listo para recoger",
                    mensaje: "Su pedido "+model.ticket+" está Listo Para Recoger en sucursal "+model.sucursal.nombre,
                };
            this.modalCorreo = true;

        }).catch(err => {
            console.log(err);
            self.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al cargar datos del cliente. Intente nuevamente por favor.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });


    },

    enviarCorreo: function () {
        if (this.$refs.formcorreo.validate()) {
            var self = this;

            window.dialogLoader.show('Espere un momento por favor..');


            let data = {
                correos: self.correo.correos,
                responder_a: self.correo.responder_a,
                asunto: self.correo.asunto,
                mensaje: self.correo.mensaje,
            };
            window.funciones.enviarEmail(data).then(result => {
                window.dialogLoader.showSnackbar('Correo enviado correctamente', {
                    color: 'success'
                });
                self.modalCorreo = false;
            }).catch(err => {
                console.log(err);
                self.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al enviar el correo. Intente nuevamente por favor.",
                    footer: ""
                });
            }).then(() => {
                window.dialogLoader.hide();
            })


        }
    },

    imprimirOrdenSalida: function(id_venta){
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;

        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_venta)
        .then(response => {
            this.model_detalle = response.data;
            for(var key in this.model_detalle["articulos"]){
                this.model_detalle["articulos"][key]["precio"] = this.formatNumber(this.model_detalle["articulos"][key]["precio"].toFixed(2));
                this.model_detalle["articulos"][key]["subtotal"] = this.formatNumber(this.model_detalle["articulos"][key]["subtotal"].toFixed(2));
            }

            var img = document.createElement("img");
            JsBarcode(img, this.model_detalle["ticket"], {format: "CODE128"});

            var columns = [
                {title: "Cantidad", dataKey: "cantidad"},
                {title: "Artículo", dataKey: "nombre_articulo"},
                {title: "Código", dataKey: "codigo_articulo"},
                {title: "Descripción", dataKey: "descripcion"},
                {title: "P. Unitario", dataKey: "precio"},
                {title: "Importe", dataKey: "subtotal"},
            ];

            //setTimeout(function(){
                var doc = new jsPDF('p', 'mm', 'letter');

                doc.setFontSize(14);
                doc.setFont('Helvetica','bold');
                doc.text('LA BODEGUITA DE GUADALUPE', 120, 23, {align:'center'});
                doc.setFontSize(9);
                doc.text('RFC: BGU960207S5A', 120, 30, {align:'center'});
                doc.setFontSize(12);
                doc.text('Orden de Salida', 15, 40);
                doc.setFontSize(8);
                doc.setFont('Helvetica','normal');
                doc.text('Cliente: '+this.model_detalle["cliente"]["nombre"], 15, 45);
                doc.text('Sucursal: '+this.model_detalle["sucursal"]["nombre"], 15, 50);
                doc.text('Fecha Elaboración: '+ this.parse_date_time_mx(this.model_detalle["fecha"]), 15, 55);
                doc.text('Fecha Impresión: '+ this.parse_date_time_mx(window.moment()), 15, 60);
                doc.text('Le Atendió: '+this.model_detalle["usuario_venta"], 15, 65);
                doc.addImage(img.src, 'PNG', 145, 40, 50, 17);

                doc.setFontSize(8);
                doc.autoTable(columns, this.model_detalle["articulos"], {
                    startY: 70,
                    headStyles:{fillColor:[26,189,156],fontSize:8},
                    bodyStyles:{fontSize:8},
                    columnStyles: { codigo_articulo:{fontSize:6},precio: { halign: 'right' },subtotal: { halign: 'right' }, cantidad: { halign: 'right' } },
                    foot: [[
                            {content: window.funciones.numeroALetras(this.model_detalle["total"], ''), rowSpan:3, colSpan:4, styles:{halign:'center',valign:'middle'}},
                            {content:"SUBTOTAL:", styles:{halign:'right'}},
                            {content:this.formatNumber(this.model_detalle["subtotal"].toFixed(2)), styles:{halign:'right'}},
                        ],[
                            {content:"IMPUESTOS:", styles:{halign:'right'}},
                            {content:this.formatNumber(this.model_detalle["impuestos"].toFixed(2)), styles:{halign:'right'}},

                        ],
                        [
                            {content:"TOTAL:", styles:{halign:'right'}},
                            {content:this.formatNumber(this.model_detalle["total"].toFixed(2)), styles:{halign:'right'}},

                        ]],
                    footStyles:{fillColor:[234,250,241],textColor:"black", fontSize:8},
                });

                let finalY = doc.lastAutoTable.finalY; // The y position on the page
                doc.text('Gracias por su compra', 15, finalY+10);
                doc.text('Observaciones: '+this.model_detalle["observaciones"], 15, finalY+20);

                doc.setFont('Helvetica','bold');
                doc.text('____________________________________________________', 110, finalY+30, {align:'center'});
                doc.text('Firma de conformidad', 110, finalY+35, {align:'center'});

                doc.save(this.model_detalle["ticket"]+"_orden_salida.pdf");
                window.dialogLoader.hide();
            //},500);

        }) .catch(error => {
            console.log(error);
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar la venta. Intente nuevamente.",
                footer: ""
            });
            window.dialogLoader.hide();
        });

    },

    cancelarComprometido: function(articulo){
        var self = this;
       return new Promise(function(resolve, reject) {
              var lotes = articulo.lotes;
              // llamar cancelarComprometido con los lotes guardados
              //Regresar el inventario comprometido al actual
              window.funciones_lotes.cancelarComprometido(lotes).then(result =>{
                  return resolve(true);
              }).catch( error => {
                  return reject(error);
              });
       });
    },

    cancelar_venta:async function(id_venta){
       window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        var errores = [];

        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_venta)
        .then(async response => {
            this.model_detalle = response.data;

            this.model_detalle["venta_cancelada"] = JSON.parse(JSON.stringify(this.model_detalle));
            this.model_detalle["estatus"] = "Cancelada";
            this.model_detalle["estatus_pedido"] = "Cancelado"; //Enviado, En Proceso, Cancelado, Listo Para Recoger, Finalizado
            this.model_detalle["cancelado_por"] = {"nombre":self.$local_storage.get("sb_nombre_usuario")}
            //- cancelar comprometido
            for(var k in this.model_detalle["articulos"]){
                if(this.model_detalle["articulos"][k].lotes != null){
                  await this.cancelarComprometido(this.model_detalle["articulos"][k]).then(result => {
                      //nada
                  }).catch(err => {
                      console.log(this.model_detalle["articulos"].nombre_articulo);
                      console.log(err);
                      errores.push(err);
                  });
                }
            }

            if(errores.length == 0){

                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_detalle._id + '?conflicts=true', this.model_detalle)
                    .then(response => {
                        this.model_detalle._rev = response.data.rev;
                        window.dialogLoader.showSnackbar('El proceso finalizó correctamente... ', {
                            color: 'success'
                        });
                        this.consultar_ventas();
                    })
                    .catch(error => {
                      console.log(error);
                        this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al cancelar la venta. Por favor intente nuevamente.",
                                footer: ""
                            });
                    }).then(() => {
                        window.dialogLoader.hide();
                    });
            } else {
              console.log(errores);
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al cancelar la venta. Intente nuevamente.",
                    footer: ""
                });
            }

            //- Preguntar si quieren que se avise al cliente cuando se cancela su pedido?


        }) .catch(error => {
            console.log(error);
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar la venta. Intente nuevamente.",
                footer: ""
            });
            window.dialogLoader.hide();
        });
    },

  }
}
</script>
<style scoped>
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: black;
  font-size: small;
  background-color: #EEEEEE;
  padding: 0px;
}

.v-tabs .v-tabs-bar .v-tab.v-tab--active {
  color: white;
  font-size: small;
  background-color: #df7205;
  padding: 0px;
  font-weight: bold;

}

.v-tabs-slider-wrapper {
  left: 0 !important;
}

.table-bordered thead th,
.table-bordered thead td {
  font-size: x-small !important;
}
</style>
